ul#menu{
    padding-inline-start: 0;
}
ul#menu li {
    display:inline;
  }

  table{
     
      margin-bottom: 25px;
  }

.loading {
    display: none; /* Hidden by default */
    position: fixed;  /* Stay in place */
    z-index: 3; /* Sit on top */
    top: 0;
    left: 0;
    margin-left: 225px;
    width: calc(100% - 225px); /*Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
    justify-content: center;
}

.loading-mobile {
    display: none; /* Hidden by default */
    position: fixed;  /* Stay in place */
    z-index: 3; /* Sit on top */
    top: 0;
    left: 0;
    width: 100%; /*Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
    justify-content: center;
}

.loading-wrapper {
    position: absolute;
    top: 45%;
    left: 50%;
    z-index: 999999999;
    transform: translate(-50%, -50%);
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0);
    margin: 100px auto 0;
    transition: all .8s;
    width: 60%;
    max-height: 80vh;
    overflow-y: hidden;
}

.loading-wrapper-login {    
    left: 50%;    
}

.loading-body {
    padding: 10px 15px;
    text-align: center;
    top: 50%;
    left: 50%;
    justify-content: center;
    justify-items: center;
    align-items: center;
    height: 100%;
    margin: 0;
    display: flex;
}


.modal {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 5;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.fullscreen-modal {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 1050 !important;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/*.modal {
    display: none;*/ /* Hidden by default */
    /*position: fixed;*/ /* Stay in place */
    /*z-index: 1;*/ /* Sit on top */
    /*left: 0;
    top: 0;
    width: 100%;*/ /* Full width */
    /*height: 100%;*/ /* Full height */
    /*overflow: auto;*/ /* Enable scroll if needed */
    /*background-color: rgb(0,0,0);*/ /* Fallback color */
    /*background-color: rgba(0,0,0,0.4);*/ /* Black w/ opacity */
/*}*/

.modal-container {
    flex-basis: 50%;
    padding: 1rem;
    background-color: #fff;
    border-radius: 3px;
}

.modal-header {
    display: flex;
    font-weight: bold;
}

.modal-close {
    margin-left: auto;
    color: inherit;
    text-decoration: none;
    margin-top: -.5rem;
    font-size: 2rem;
}

.modal-overlay {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    opacity: 0;
    transition: opacity .2s ease;
}

.wide-modal {
    width: 74% !important;
    left: 13% !important;
}

.modal-wrapper {
    position: absolute;
    top: 0vh;
    left: 20%;
    z-index: 999999999;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ebeef0;
    border-radius: .4em;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    margin: 100px auto 0;
    transition: all .8s;
    width: 60%;
    max-height: 75vh;
    overflow-y: scroll;
}
.modal-wrapper-servicetitan {
    position: absolute;
    top: -2%;
    left: 20%;
    z-index: 999999999;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ebeef0;
    border-radius: .4em;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    margin: 100px auto 0;
    transition: all .8s;
    width: 60%;
    max-height: 90vh;
    overflow-y: scroll;
}
.modal-wrapper-white {
    position: absolute;
    top: 0;
    left: 20%;
    z-index: 999999999;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ebeef0;
    border-radius: .4em;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    margin: 100px auto 0;
    transition: all .8s;
    width: 60%;
    max-height: 80vh;
    overflow-y: scroll;
}

.modal-wrapper-gateway {
    position: absolute;
    top: 0;
    left: 10%;
    z-index: 999999999;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ebeef0;
    border-radius: .4em;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    margin-top: 100px;
    transition: all .8s;
    width: 80%;
    max-height: 80%;
    overflow-y: scroll;
}

.small-modal-wrapper {
    position: absolute;
    top: 0;
    left: 40%;
    z-index: 999999999;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ebeef0;
    border-radius: .3em;
    box-shadow: 1px 5px 8px 1px rgba(0,0,0,0.2), 2px 7px 20px 2px rgba(0,0,0,0.17);
    margin: 100px auto 0;
    transition: all .8s;
    max-width: 40%;
    max-height: 80vh;
}

.big-modal-wrapper {
    position: absolute;
    top: 0;
    left: 1%;
    z-index: 999999999 !important;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ebeef0;
    border-radius: .5em;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    margin-top: 50px;
    transition: all .8s;
    width: 98%;
    max-height: calc(100% - 55px);
    overflow-y: scroll;
}

.forgot-password-modal-wrapper {
    position: absolute;
    top: 0;
    z-index: 999999999 !important;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ebeef0;
    border-radius: .5em;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    margin-top: 50px;
    height: 90%;
    transition: all .8s;
    overflow-y: scroll;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.modal-header {
    background: white; /*#166A96;
    background: -webkit-linear-gradient(top, #166A96 0%,#166A96 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#166A96),to(#166A96));
    background: -o-linear-gradient(top, #166A96 0%,#166A96 100%);
    background: linear-gradient(to bottom, #166A96 0%,#166A96 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#166A96', endColorstr='#166A96',GradientType=0 );*/
    height: 60px;
    line-height: 40px;
    padding: 12px 20px;
    text-align: center;
    border-radius: 0;
    position: sticky;
    top: 0;
    left: 10px;
    width: 100%;
    z-index: 99999999999;
}

.modal-header h3 {    
    color: black;    
    margin: 0;
    padding: 0;
}

.modal-body {
    padding: 10px 15px;
    text-align: center;
}

.forgot-password-modal-body {
    height: 100%;
    width: 100%;
}

/*.modal-body input{
    width: 300px;
}*/

.modal-body input[type="text"] {
    color: black;
}

.modal-body-2 {
    padding: 10px 15px;
    text-align: center;
}

.h-500 {
    height: 500px;
}

.modal-footer {
    background: white; /*#166A96;
    background: -webkit-linear-gradient(top, #166A96 0%,#166A96 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#166A96),to(#166A96));
    background: -o-linear-gradient(top, #166A96 0%,#166A96 100%);
    background: linear-gradient(to bottom, #166A96 0%,#166A96 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#166A96', endColorstr='#166A96',GradientType=0 );*/
    height: 50px;
    line-height: 40px;
    padding: 12px 20px;
    text-align: center;
    border-radius: 0;
}

.modal-footer button {
    min-width: 62px;
}

.modal-button{
    border: 0;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    line-height: 1;
}

.close-modal-btn {
    color: #0033a0;
    cursor: pointer;
    float: right;
    font-size: 30px;
    margin: 0;
}

.close-modal-btn:hover {
    color: gray;
}

.btn-cancel, .btn-continue {
    background: coral;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: bold;
    outline: none;
    padding: 10px;
}

.btn-cancel {
    background-color: #b71c1c;
    float: left;
}

.btn-continue {
    background-color: #1b5e20;
    float: right;
}

.back-drop {
    background-color: rgba(48, 49, 48, 0.42);
    height: 100%;
    position: fixed;
    transition: all 1.3s;
    width: 100%;
}

.open-modal-btn {
    margin: 15px;
    padding: 10px;
    font-weight: bold;
}

.email-wrapper {
    margin: 1rem 1rem;
}

.email-wrapper-body {
    display: inline-block;
    padding: 1rem 1rem;
}

.react-datepicker-popper {
    z-index: 9999999999 !important;
}

.disable-textBox{
    background: #CCC;
    color: #333;
    border: 1px solid #666
}

.sensor-map-item {
    background-color: white;
    padding: 8px;
    margin: 8px !important;
    border: 0.5px solid lightgray;
    border-radius: 0.4rem;
}

.sensor-selected {
    border-color: #0033a0;
    background-color: #f1f6ff;
    box-shadow: 1px 1px 6px #0033a035;
}

.sensor-dragged {
    border-color: #46a000;
    background-color: #f1fff2;
    box-shadow: 1px 1px 6px #46a00035;
}
